<template>
  <div class="w-screen h-screen flex items-center justify-center">
    <div class="text-center">
      <div
        class="rounded-full bg-white mx-auto dark:bg-dark-700 relative p-5 shadow text-lg w-auto w-24"
      >
        <img src="@/assets/favicon.png" class="w-full" alt="" />
        <i
          class="fas fa-spinner animate-spin dark:text-white opacity-50 mt-3"
        ></i>
      </div>
      <h3 class="mt-5">
        Your payment is processing...
      </h3>
      <powered-by />
    </div>
  </div>
</template>

<script>
import PoweredBy from '@/components/PoweredBy';
import axios from 'axios';
import { LCDClient } from '@terra-money/terra.js';

export default {
  components: {
    PoweredBy,
  },

  async mounted() {
     this.terra = new LCDClient({
      URL: `${process.env.VUE_APP_LCD_URL}`,
      chainID: `${process.env.VUE_APP_CHAIN_ID}`,
    });



    this.orderId = this.$route.query.order_id;
    this.redirect = this.$route.query.redirect;
    this.txHash = this.$route.query.txhash;


    let txData = JSON.stringify({
      txHash: this.txHash
    });


    setTimeout(function() {
      axios.post(`${process.env.VUE_APP_PUBLIC_FUNCTION}`, txData)
    }, 6000);

  
    this.confirmTX = setInterval(this.confirmTX, 2000);
    this.dataInterval = setInterval(this.fetchData, 3000);
    },

  methods: {
    async fetchData() {
      let orderResponse = await axios.get(
        `${process.env.VUE_APP_ASTRAL_PAYMENTS_API}/order?order_id=${
          this.orderId
        }&timestamp=${new Date().getTime()}`
      );

      if (orderResponse.data.status == 'paid') {
        this.order.status = 'paid';
        clearInterval(this.dataInterval);
        this.$router.push(`/success?order_id=${this.orderId}&redirect=${this.redirect}`)
      }

      return orderResponse.data;
    },

    async confirmTX(){
      let txStatus = await this.terra.tx.txInfo(this.txHash);
      console.log(txStatus.logs);
    }
  }
};
</script>

<style></style>
