import Vue from 'vue';
import App from './App.vue';
import './styles/globals.css';
import './styles/app.scss';
import router from './router';
import store from './store';
import vueNumeralFilterInstaller from 'vue-numeral-filter';

Vue.config.productionTip = false;

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
