<template>
  <div class="max-w-[570px] mx-auto p-5">
    <span @click="exitPayment"
    style="cursor: pointer;"
      class="text-xs text-header dark:text-paragraph-dark flex items-center my-2"
      ><i
        style="cursor: pointer;"
        class="fas fa-chevron-left mr-2 opacity-50"
      ></i>
      <img
        v-bind:src="order.logo"
        height="22"
        width="22"
        class="rounded-full mr-2"
        alt=""
      />
      {{ order.merchant }}</span
    >
    <div class="title-area flex mt-5 mb-5">
      <h3 class="mb-0">Your transaction</h3>
      <div v-if="chainId == 'columbus-5'" class="ml-auto">
        <span class="tag positive">Mainnet</span>
      </div>
      <div v-else class="ml-auto">
        <span class="tag positive">Testnet</span>
      </div>
    </div>

    <widget>
      <div
        v-if="order.status == 'unpaid'"
        class="header-padding flex border-b items-center"
      >
        <small class="animate-pulse"
          ><i class="fas fa-spinner mr-1 animate-spin"></i> Waiting for
          payment</small
        >
        <div class="ml-auto">
          <span class="tag">Unpaid</span>
        </div>
      </div>
      <div v-else class="header-padding flex border-b items-center">
        <small class="animate-pulse"
          ><i class="fas fa-check mr-1"></i> Payment successful!</small
        >
        <div class="ml-auto">
          <span class="tag positive">Paid</span>
        </div>
      </div>

      <div class="inner-padding">
        <div class="payment-info flex justify-end">
          <div
            class="row w-full max-w-full text-paragraph dark:text-paragraph-dark"
          >
            <div class="flex my-2">
              <div class="w-1/2">Price</div>
              <div class="w-1/2 text-right">
                <Skeleton>
                  {{ order.amount | numeral('0,0.00') }}
                  {{ order.currency }}
                </Skeleton>
              </div>
            </div>
            <div class="flex my-2">
              <div class="w-1/2">Fee</div>
              <div class="w-1/2 text-right">
                <Skeleton>
                  {{ (order.numericFee / 10 ** 6) | numeral('0,0.00') }}
                  {{ order.currency }}
                </Skeleton>
              </div>
            </div>
            <hr class="my-4" />
            <div class="flex my-2">
              <div class="w-1/2">Total</div>
              <div
                class="font-medium w-1/2 text-right dark:text-header-dark text-header"
              >
                <Skeleton>
                  {{
                    (Number(order.amount) + order.numericFee / 10 ** 6)
                      | numeral('0,0.00')
                  }}
                  {{ order.currency }}
                </Skeleton>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center mt-5">
          <div>
            <h4 class="mb-0">Pay with Terra Station</h4>
            <small>via Google Chrome Plugin</small>
          </div>
          <div v-if="extension.isAvailable" class="ml-auto">
            <button
              v-on:click="pay"
              :disabled="isIncorrectChain"
              :class="
                !isIncorrectChain
                  ? 'btn btn-primary'
                  : 'btn btn-primary opacity-50 cursor-not-allowed'
              "
            >
              Pay
            </button>
          </div>
          <div v-else class="ml-auto">
            <button v-on:click="installExtension" class="btn btn-primary">
              Install
            </button>
          </div>
        </div>
        <div
          v-if="!sufficientBalance && !isIncorrectChain"
          class="mt-4 text-right"
        >
          <span class=" text-xs text-caution animate-pulse"
            >Not enough {{ order.currency }} to make payment
            <i class="fas fa-exclamation-triangle"></i
          ></span>
        </div>
        <div v-if="isIncorrectChain" class="mt-4 text-right">
          <span class=" text-xs text-caution animate-pulse"
            >Please switch your network to {{ switchChain }}
            <i class="fas fa-exclamation-triangle"></i
          ></span>
        </div>
      </div>
    </widget>

    <widget>
      <div class="header-padding">
        <span class="h4 mb-0">Manual transfer</span>
      </div>
      <div class="pb-6 px-6">
        <div class="bg-light-700 dark:bg-dark-700 p-6 rounded">
          <div class="mb-5">
            <small>Amount</small>
            <span class="block text-header dark:text-header-dark">
              <Skeleton
                >{{ order.amount | numeral('0,0.00') }}
                {{ order.currency }}</Skeleton
              >
            </span>
          </div>
          <div class="mb-5">
            <small>Address</small>
            <span class="block text-header dark:text-header-dark break-all"
              ><Skeleton
                >{{ order.merchantAddress }}
                <button>
                  <i class="fas fa-copy ml-1 opacity-25 hover:opacity-100"></i>
                </button>
              </Skeleton>
            </span>
          </div>
          <div>
            <small>Memo</small>
            <span class="block text-header dark:text-header-dark">
              <Skeleton
                >{{ order.memo }}
                <button>
                  <i
                    class="fas fa-copy ml-1 opacity-25 hover:opacity-100"
                  ></i></button></Skeleton
            ></span>
          </div>
        </div>
        <div class="mt-3 text-right">
          <span class=" text-xs text-caution"
            >These details must match when making a payment
            <i class="fas fa-exclamation-triangle"></i
          ></span>
        </div>
      </div>
    </widget>
    <powered-by />
  </div>
</template>

<script>
import axios from 'axios';
import  Router from '../router'
import {
  LCDClient,
  Extension,
  MsgSend,
  Coin,
  StdFee,
} from '@terra-money/terra.js';
import Widget from '@/components/Widget';
import PoweredBy from '@/components/PoweredBy';
import { Skeleton } from 'vue-loading-skeleton';
export default {
  components: {
    Widget,
    PoweredBy,
    Skeleton,
  },
  metaInfo: {
    title: 'Simple Digital Finance',
  },
  data() {
    return {
      terra: undefined,
      chainId: undefined,
      orderId: undefined,
      redirect: undefined,
      order: {
        currency: '',
        amount: undefined,
        merchant: '',
        merchantAddress: '',
        memo: '',
        fee: undefined,
        totalFee: new Coin('uusd', 0),
        numericFee: undefined,
        status: 'unpaid',
      },
      userAddress: undefined,
      gas: 120000,
      sufficientBalance: true,
      switchChain: undefined
    };
  },

  computed: {
    isIncorrectChain() {
      return !(this.switchChain == undefined);
    },
  },

  async created() {
    this.terra = new LCDClient({
      URL: `${process.env.VUE_APP_LCD_URL}`,
      chainID: `${process.env.VUE_APP_CHAIN_ID}`,
    });

    this.chainId = process.env.VUE_APP_CHAIN_ID;

    this.extension = new Extension();
    if(Array.isArray(this.$route.query.order_id))
    {
      this.orderId = this.$route.query.order_id[0];
    }
    else {
      this.orderId = this.$route.query.order_id;
    }
    this.redirect = this.$route.query.redirect;
    let orderData = await this.fetchData();

    this.order = {
      currency: orderData.currency
        .substring(0, orderData.currency.length - 1)
        .concat('T'),
      amount: orderData.amount,
      terra_amount: orderData.terra_amount,
      merchant: orderData.merchant,
      merchantAddress: orderData.wallet,
      memo: orderData.id,
      logo: orderData.logo,
      denom: orderData.denom,
      fee: undefined,
      numericFee: undefined,
      status: orderData.status,
    };

    let gasPriceList = await axios.get(
      `${process.env.VUE_APP_FCD_URL}/v1/txs/gas_prices`
    );

    const gasPrice = gasPriceList.data[this.order.denom];

    const gasAmount = this.gas;
    this.order.fee = gasPrice * gasAmount;
    this.order.totalFee = await this.calculateFee();
    this.order.numericFee = parseInt(this.order.totalFee.toString());
  },

  async mounted() {
    this.connect();

    this.dataInterval = setInterval(this.fetchData, 4000);
    this.balanceInterval = setInterval(this.checkBalance, 3000);
    this.balanceInterval = setInterval(this.checkNetwork, 1000);
  },

  methods: {
    async connect() {
      let response = await this.extension.request('connect');
      if (response.payload.address) {
        this.userAddress = response.payload.address;
      }
    },

    async pay() {
      if (!this.userAddress) {
        this.connect();
      }

      let self = this;
      this.extension.on("onPost", function(event){
        console.log(event);
        console.log(event.result);
        let txhash = event.result.txhash;
        Router.push(
          `/processing?order_id=${self.orderId}&redirect=${self.redirect}&txhash=${txhash}`
        );
      });

      if (this.userAddress) {
        const msg = new MsgSend(this.userAddress, this.order.merchantAddress, [
          new Coin(this.order.denom, this.order.terra_amount),
        ]);

        const res = await this.extension.request('post', {
          msgs: [msg.toJSON()],
          memo: this.order.memo,
          gasAdjustment: 1.2,
          fee: new StdFee(this.gas, [this.order.totalFee]).toJSON(),
          purgeQueue: true,
        });

        console.log(res);

      }
    },

    async calculateFee() {
      let taxRate = await this.terra.treasury.taxRate();

      let rates = await this.terra.oracle.exchangeRates();
      let SDR = rates.get('usdr').amount;
      let denomRate = rates.get(this.order.denom).amount;

      let maxSDR = 1 * (denomRate / SDR) * 10 ** 6;
      let totalFee = new Coin(
        this.order.denom,
        this.order.fee +
          Math.floor(Math.min(maxSDR, this.order.terra_amount * taxRate))
      );
      return totalFee;
    },

    async fetchData() {
      let orderResponse = await axios.get(
        `${process.env.VUE_APP_ASTRAL_PAYMENTS_API}/order?order_id=${
          this.orderId
        }&timestamp=${new Date().getTime()}`
      );


      if (orderResponse.data.status == 'paid') {
        this.order.status = 'paid';
        clearInterval(this.dataInterval);
        clearInterval(this.checkBalance);
        clearInterval(this.checkNetwork);
        this.$router.push(`/success?order_id=${this.orderId}&redirect=${this.redirect}`)
      }

      return orderResponse.data;
    },

    async checkNetwork() {
      if (this.extension) {
        const res = await this.extension.request('info');
        if (res.payload.chainID != process.env.VUE_APP_CHAIN_ID) {
          this.switchChain = process.env.VUE_APP_CHAIN_NAME;
        } else {
          this.switchChain = undefined;
        }
      }
    },

    exitPayment() {
      window.history.back();
    },

    async checkBalance() {
      if (this.userAddress) {
        let balances = await this.terra.bank.balance(this.userAddress);

        let totalAmountDue = this.order.totalFee.add(
          new Coin(this.order.denom, this.order.terra_amount)
        );
        let diff = balances.get(this.order.denom).sub(totalAmountDue);
        this.sufficientBalance = parseInt(diff.amount.toString()) > 0;
      }
    },

    installExtension() {
      window.open(
        'https://chrome.google.com/webstore/detail/terra-station/aiifbnbfobpmeekipheeijimdpnlpgpp?hl=en'
      );
    },
  },
};
</script>
