<template>
  <div>
    <button
      v-on:click="darkToggle"
      class="darkToggle"
      :class="{ isTrue: darkMode }"
    >
      <span class="text-white flex items-center justify-center">

        <div v-show="!darkMode">
          <SunIcon class="w-5"></SunIcon>
        </div>
        <div v-show="darkMode">
          <MoonIcon class="w-5"></MoonIcon>
        </div>
      </span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.darkToggle {
  @apply bg-light-600 rounded-full relative flex items-center justify-center transition-transform;
  width: 60px;
  height: 30px;
  outline: none;
  .dark & {
    @apply bg-dark-700;
  }
  > span {
    width: 26px;
    height: 26px;
    @apply relative bg-primary rounded-full text-white -translate-x-1/2 transition-transform;
    margin-left:-4px;
  }
  &.isTrue {
      > span {
        @apply translate-x-1/2;
        margin-left:4px;
      }
  }
}
</style>

<script>
import { mapState, mapMutations } from 'vuex';
import { SunIcon, MoonIcon } from '@vue-hero-icons/outline';
export default {
  components: {
    SunIcon,
    MoonIcon
  },
  computed: {
    ...mapState(['darkMode']),
  },
  methods: {
    ...mapMutations(['darkToggle']),
  },
  beforeMount() {
    const app = document.body;
    if (!this.darkMode) {
      app.classList.remove('dark');
    } else {
      app.classList.add('dark');
    }
  },
};
</script>
