import Vue from 'vue';
import VueRouter from 'vue-router';
import Payment from '../views/Payment.vue';
import Processing from '../views/Processing.vue';
import Success from '../views/Success.vue';
import Failed from '../views/Failed.vue';
import VueMeta from 'vue-meta';

Vue.use(VueMeta);
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Payment',
    component: Payment,
  },
  {
    path: '/processing',
    name: 'Processing',
    component: Processing,
  },
  {
    path: '/success',
    name: 'Success',
    component: Success,
  },
  {
    path: '/failed',
    name: 'Failed',
    component: Failed,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
