<template>
  <div class="w-screen h-screen flex items-center justify-center">
    <div class="text-center">
      <div
        class="rounded-full bg-white mx-auto dark:bg-dark-700 relative p-5 shadow text-lg w-auto w-24"
      >
        <img src="@/assets/favicon.png" class="w-full" alt="" />
        <i class="fas fa-check animate-bounce text-positive mt-3"></i>
      </div>
      <h3 class="mt-5 mb-0 text-positive">
        Payment succeeded
      </h3>
      <div class="mb-5">
        <small>You'll be directed back to the store in 3 seconds...</small>
      </div>
      <button @click="exitPayment" class="btn btn-neutral">Return now</button>
      <br /><br />
      <powered-by />
    </div>
  </div>
</template>

<script>
import PoweredBy from '@/components/PoweredBy';

export default {
  components: {
    PoweredBy,
  },

  async mounted() {
    this.orderId = this.$route.query.order_id;
    this.redirect = this.$route.query.redirect;
    setTimeout(this.exitPayment, 3000)
  },

  methods: {
  
  
  exitPayment() {
      window.location.href = this.redirect;
    }
  }
};
</script>

<style></style>
