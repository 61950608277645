<template>
  <div>
    <small class="flex w-full justify-center items-center"
      >Powered by <img src="@/assets/favicon.png" class="w-4 mx-1" alt="" />
      <span class="font-medium">Astral</span></small
    >
  </div>
</template>

<script>
export default {};
</script>

<style></style>
