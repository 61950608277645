<template>
  <div class="bg-white dark:bg-dark-800 shadow rounded my-4">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header-padding {
  @apply py-4 px-6;
}
.inner-padding {
  @apply p-6;
}
</style>
