<template>
  <div id="app">
    <div class="min-h-screen w-full bg-light-800 dark:bg-dark-900">
      <dark-toggle class="absolute top-4 right-4"></dark-toggle>
      <router-view />
    </div>
  </div>
</template>

<script>
import DarkToggle from '@/components/DarkToggle';
export default {
  components: {
    DarkToggle,
  },
  metaInfo: {
    title: 'Astral',
    titleTemplate: 'Astral | %s',
    meta: [
      { name: 'description', content: 'The space eco-system built on Terra' },
    ],
  },
};
</script>

<style lang="scss" scoped>
#app {
  @apply bg-dark-900 h-full h-screen;
}
</style>
