import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    darkMode: false,
  },
  mutations: {
    darkToggle() {
      this.state.darkMode = !this.state.darkMode;

      // simple light/dark toggle
      const app = document.body;
      if (!this.state.darkMode) {
        app.classList.remove('dark');
      } else {
        app.classList.add('dark');
      }
    },
  },
  plugins: [createPersistedState()],
});

export default store;
